import axios from 'axios'
import type { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

const urlParams = new URLSearchParams(window.location.search)
const newInstance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
})

newInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {

    // @ts-ignore
    config.headers = {
      ...config.headers,
      'x-lang': urlParams.has('user_lang') ? urlParams.get('user_lang').toLocaleLowerCase() : 'en',
      'x-token': urlParams.get('token') ? urlParams.get('token') : undefined,
      'x-access-token': localStorage.getItem('LOCA_ACCESS_TOKEN') ?? undefined,
      'x-referral': urlParams.has('referral') ? urlParams.get('referral').toLocaleLowerCase() : undefined,
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('HTTP_REQUEST', {
        baseUrl: config.baseURL,
        url: config.url,
        headers: config.headers,
        data: config.data ?? undefined
      })
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  })

// ADD A 401 RESPONSE INTERCEPTOR
newInstance.interceptors.response.use((response: AxiosResponse) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('HTTP_RESPONSE', response.data)
  }
  return response
}, (error) => {
  return Promise.reject(error)
})

export const axiosInstance = newInstance
